<template>
  <!-- Swimming upstream section -->
  <div class="relative">
    <!-- Yellow shadow on the left -->
    <span
      class="absolute top-40 lg:top-1/2 left-0 -translate-y-1/2 z-[-1] w-full max-w-[350px] left_yellow_shadow shadow-animation"
    >
      <YellowShadowLeft />
    </span>

    <!-- Content container -->
    <div
      class="xl:max-w-[1344px] px-3 container mx-auto py-10 sm:py-14 md:py-24 lg:py-32 xl:py-[150px]"
    >
      <div
        class="flex items-center justify-center gap-8 sm:gap-10 md:gap-12 lg:gap-[75px] flex-col lg:flex-row"
      >
        <!-- Illustration -->
        <img
          class="w-full max-w-[350px] lg:max-w-[450px] xl:max-w-[600px]"
          src="/assets/images/swimming/illustration.webp"
          alt="illustration"
        />
        <!-- Text content -->
        <div class="max-w-[562px] text-center lg:text-start">
          <!-- Title -->
          <h2
            class="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-[50px] text-lightBlack !leading-[120%] md:pb-[14px] ff_recoleta"
          >
            {{ swimmingUpstream.title }}
          </h2>
          <!-- Description -->
          <p
            class="font-normal text-base md:text-lg !leading-[140%] pt-3 sm:mt-4"
            v-for="(data, index) in swimmingUpstream.description"
            :key="index"
          >
            {{ data }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Importing the yellow shadow icon
import YellowShadowLeft from "./icons/YellowShadowLeft.vue";

// Custom swimming upstream data
const swimmingUpstream = {
  title: "Swimming Upstream with your Finances?",
  description: [
    "If you are making multiple to stops for check casing, remittance, and spending needs than you are paying a fee for each of those services separately.\
    Canal gives you back some of your hard earned time and money by bundling all of these services into one easy to use app.\
    This lets us be your partner and get paid by the Card Networks instead of you.",
  ],
};
</script>

<style>
.left_yellow_shadow svg {
  width: 100%;
}
</style>
