/* eslint-disable no-unused-vars */
import emailjs from "@emailjs/browser";

export default class Emailer {
    constructor() {
        /**
         * @INIT the emailjs email client.
         */
        try {
            // read env variables
            const pubKey = process.env.VUE_APP_EMAIL_PUBKEY;
            const serviceID = process.env.VUE_APP_EMAIL_SERVICE_ID;

            const templateIDs = {
                // join waitlist form
                joinWaitlist: {
                    cssFormRef: "mainform",
                    templateID: process.env.VUE_APP_EMAIL_WAITLIST_TEMPLATE_ID,
                    templateParams: {
                        // user email address
                        user_email: "string",
                    },
                }
            }
            // assign to instance
            this.pubKey = pubKey;
            this.serviceID = serviceID;
            this.templateIDs = templateIDs;

            this.emailClient = emailjs.init({
                publicKey: pubKey,
                // NOTE do not allow headless browsers?
                blockHeadless: true,
                blockList: {
                    // block suspended emails
                    list: [],
                    // the variable containing the email address
                    watchVariable: "user_email",
                },
                limitRate: {
                    // set the limit rate for the application
                    id: "app", // NOTE a Vue.JS specific hard coded value
                    // allow 1 request per 10 seconds
                    throttle: 10000,
                }
            });
        } catch (error) {
            this.emailClient = null;
            throw new Error("Failed to initialize email client.");
        }
    }

    sendJoinWaitlistEmail(templateParams) {
        try {
            // create a deepcopy of the template params
            const emailData = Object.assign({}, this.templateIDs.joinWaitlist.templateParams);
            // validate the params
            for (const [key, _] of Object.entries(this.templateIDs.joinWaitlist.templateParams)) {
                // param to check
                const paramCheck = templateParams[key];
                // value is not null, throw error
                if (!paramCheck) {
                    throw new Error(`Missing template parameter: ${key}`);
                }
                // ensure value converts to a string
                if (typeof paramCheck !== "string") {
                    paramCheck.toString();
                }
                // replace the default type string value with the template ID param value in the deep copy
                emailData[key] = paramCheck;
                // the template params has everything it needs for the join waitlist email!
            }
            // send the email
            return emailjs.send(this.serviceID, this.templateIDs.joinWaitlist.templateID, emailData)
                .then((_response) => {
                    // return true to indicate success to the caller
                    console.log("send email: ", _response)
                    return true;
                })
                .catch((_error) => {
                    // TODO settle on how to handle submission
                    //throw new Error("Failed to send validated params via email client.");
                    console.log("send email error: ", _error)
                    return false;
                });
        } catch (error) {
            // TODO settle on how to handle submission
            //throw new Error("Failed to send join waitlist email.");
            console.log("send email error: ", error)
            return false;
        }
    }

    /**
     * @GETTER template params getter
     */
    getTemplates() {
        return this.templateIDs;
    }
}