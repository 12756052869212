import { ref } from 'vue';

export function useModal() {
    // reactive variable for toggling modal visibility
    const showModal = ref(false);
    // method to toggle visibility of the modal
    function toggleModal() {
        showModal.value = !showModal.value;
    }
    // close the modal

    function closeModal() {
        showModal.value = false;
    }
    return {
        showModal,
        toggleModal,
        closeModal,
    }
}