<template>
  <svg
    width="519"
    height="860"
    viewBox="0 0 519 860"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5" filter="url(#filter0_f_20_11245)">
      <path
        d="M93.157 8.57961C-176.792 277.141 41.1599 16.5803 -388.679 657.779C-418.574 463.485 -371.026 271.703 -44.0047 15.3855C138.648 -277.265 138.877 -208.014 317.673 -390.705C282.51 -210.881 407.999 -276.879 93.157 8.57961Z"
        fill="url(#paint0_linear_20_11245)"
      />
      <path
        d="M93.157 8.57961C-176.792 277.141 41.1599 16.5803 -388.679 657.779C-418.574 463.485 -371.026 271.703 -44.0047 15.3855C138.648 -277.265 138.877 -208.014 317.673 -390.705C282.51 -210.881 407.999 -276.879 93.157 8.57961Z"
        stroke="#7210FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_20_11245"
        x="-596.753"
        y="-592.249"
        width="1115.24"
        height="1451.37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_20_11245"
        />
      </filter>
      <linearGradient
        id="paint0_linear_20_11245"
        x1="376.285"
        y1="-0.800815"
        x2="-340.462"
        y2="109.484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7210FF" />
        <stop offset="0.46" stop-color="#127780" />
        <stop offset="1" stop-color="#FAD000" />
      </linearGradient>
    </defs>
  </svg>
</template>
