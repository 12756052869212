<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="724"
    height="604"
    viewBox="0 0 724 604"
    fill="none"
  >
    <g opacity="0.5" filter="url(#filter0_f_22_12520)">
      <path
        d="M293.468 361.751C-82.7384 302.872 256.268 324.551 -514.989 357.091C-375.986 218.088 -193.212 143.022 206.869 255.166C550.879 229.403 269.049 272.282 522.104 308.433C356.298 386.415 709.847 446.841 293.468 361.751Z"
        fill="url(#paint0_linear_22_12520)"
      />
      <path
        d="M293.468 361.751C-82.7384 302.872 256.268 324.551 -514.989 357.091C-375.986 218.088 -193.212 143.022 206.869 255.166C550.879 229.403 269.049 272.282 522.104 308.433C356.298 386.415 709.847 446.841 293.468 361.751Z"
        stroke="#7210FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_22_12520"
        x="-716.25"
        y="0.354431"
        width="1440.08"
        height="603.259"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_22_12520"
        />
      </filter>
      <linearGradient
        id="paint0_linear_22_12520"
        x1="468.459"
        y1="584.523"
        x2="-44.3224"
        y2="71.7422"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7210FF" />
        <stop offset="0.46" stop-color="#127780" />
        <stop offset="1" stop-color="#FAD000" />
      </linearGradient>
    </defs>
  </svg>
</template>
