<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="566"
    height="1192"
    viewBox="0 0 566 1192"
    fill="none"
  >
    <g opacity="0.5" filter="url(#filter0_f_22_12519)">
      <path
        d="M50.7524 776.008C-173.632 468.356 50.7526 723.399 -517.618 201.047C-321.038 201.047 -138.717 277.208 64.8851 639.406C326.354 864.441 210.412 781.361 363.787 985.861C191.402 923.76 285.01 1130.6 50.7524 776.008Z"
        fill="url(#paint0_linear_22_12519)"
      />
      <path
        d="M50.7524 776.008C-173.632 468.356 50.7526 723.399 -517.618 201.047C-321.038 201.047 -138.717 277.208 64.8851 639.406C326.354 864.441 210.412 781.361 363.787 985.861C191.402 923.76 285.01 1130.6 50.7524 776.008Z"
        stroke="#7210FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_22_12519"
        x="-718.901"
        y="0.547119"
        width="1284.09"
        height="1190.57"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_22_12519"
        />
      </filter>
      <linearGradient
        id="paint0_linear_22_12519"
        x1="16.9661"
        y1="1057.27"
        x2="16.9661"
        y2="332.087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7210FF" />
        <stop offset="0.46" stop-color="#127780" />
        <stop offset="1" stop-color="#FAD000" />
      </linearGradient>
    </defs>
  </svg>
</template>
