<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="group-hover:stroke-white duration-300"
      d="M10.9395 3V18"
      stroke="#127780"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      class="group-hover:stroke-white duration-300"
      d="M3.43945 10.5L18.4395 10.5"
      stroke="#127780"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
