<template>
  <svg
    width="18"
    height="10"
    viewBox="0 0 27 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4057 15.3882C26.025 15.7799 25.5088 16 24.9706 16C24.4323 16 23.9161 15.7799 23.5355 15.3882L13.4877 5.04411L3.44 15.3882C3.05717 15.7688 2.54443 15.9795 2.01221 15.9747C1.47999 15.9699 0.970876 15.7502 0.594526 15.3627C0.218176 14.9753 0.00470165 14.4511 7.68054e-05 13.9032C-0.00454804 13.3553 0.200049 12.8274 0.569802 12.4333L12.0526 0.611838C12.4333 0.220078 12.9495 -3.77022e-07 13.4877 -3.77022e-07C14.026 -3.77022e-07 14.5422 0.220078 14.9228 0.611838L26.4057 12.4333C26.7862 12.8252 27 13.3566 27 13.9107C27 14.4649 26.7862 14.9963 26.4057 15.3882Z"
      fill="white"
    />
  </svg>
</template>
