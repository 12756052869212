<template>
  <!-- Navigation bar container -->
  <div
    class="xl:max-w-[1344px] px-3 container mx-auto py-3 sm:py-6 xl:py-[30px] flex items-center justify-between"
  >
    <!-- Background overlay for mobile -->
    <div
      @click="toggleClicked"
      class="absolute h-full w-full left-0 top-0 bg-[#000000ad] z-[4] sm:hidden"
      :class="{
        'opacity-0 z-[-1] duration-300 pointer-events-none': !isSidebarOpen,
      }"
    ></div>
    <!-- Navigation logo -->
    <a href="">
      <NavLogo />
    </a>
    <!-- Main navigation content -->
    <div class="flex items-center gap-10 lg:gap-[100px]">
      <!-- Mobile menu -->
      <div
        class="flex flex-col sm:flex-row gap-4 sm:gap-[30px] items-start sm:items-center bg-white sm:bg-transparent z-10 absolute top-6 sm:top-0 right-3 sm:right-0 sm:relative w-[180px] sm:w-fit rounded-md shadow-lg sm:shadow-none p-6 sm:p-0 duration-300"
        :class="{ 'right-[-100%]': !isSidebarOpen }"
      >
        <!-- Close button for mobile menu -->
        <span
          class="cursor-pointer absolute top-3 right-3 sm:hidden"
          @click="toggleClicked"
        >
          <Cross />
        </span>
        <!-- Navigation links -->
        <a
          @click="isSidebarOpen = false"
          class="text-lightBlack text-sm sm:text-base md:text-lg relative after:content-[''] after:w-0 after:h-[1px] after:bg-lightBlack after:absolute after:bottom-0 after:left-1/2 after:translate-x-[-50%] after:duration-300 hover:after:w-full"
          v-for="(obj, index) in navLinks"
          :key="index"
          :href="obj.linkUrl"
        >
          {{ obj.link }}
        </a>
        <!-- Join button for mobile -->
        <button
          @click="focusOnForm"
          class="text-green border-green border-[1.5px] flex sm:hidden gap-1 items-center font-semibold text-sm sm:text-base md:text-lg sm:px-5 sm:py-[13px] ps-2 pe-3 py-2"
        >
          <BtnIcon />
          Join
        </button>
      </div>
      <!-- Icons and buttons for desktop -->
      <div class="flex items-center gap-4 sm:gap-8">
        <!-- WorldNet icon -->
        <span class="cursor-pointer duration-300 hover:translate-y-[-5px]">
          <!-- <WorldNet /> -->
        </span>
        <!-- Toggle button for mobile -->
        <span class="cursor-pointer sm:hidden">
          <!-- <Toggle /> -->
        </span>
        <!-- Join button for desktop -->
        <button
          @click="focusOnForm"
          class="text-green border-green border-[1.5px] hidden sm:flex gap-1 items-center font-semibold text-sm sm:text-base md:text-lg px-5 py-[13px] duration-300 hover:bg-green hover:text-white group"
        >
          <BtnIcon />
          Join
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
// Importing components
import BtnIcon from "./icons/BtnIcon.vue";
import Cross from "./icons/CrossIcon.vue";
import NavLogo from "./icons/NavLogo.vue";
// import Toggle from "./icons/ToggleIcon.vue";
// import WorldNet from "./icons/WorldNet.vue";

// Custom navigation links data
const navLinks = [
  // {
  //   link: "Product",
  //   linkUrl: "#product",
  // },
  // {
  //   link: "Mission",
  //   linkUrl: "#mission",
  // },
];

import { ref, watch } from "vue";
// Toggle state for mobile menu
const isSidebarOpen = ref(false);

const toggleClicked = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

// When the Join button is clicked on the website,
// this function focuses the user on the waitlist form.
const focusOnForm = () => {
  /**
   * @NOTE the CSS selector #main-form refers to actual waitlist form submission.
   */
  const form = document.getElementById("mainform");
  form.scrollIntoView({ behavior: "smooth" });
};

// Watcher for toggling body overflow on mobile menu open/close
watch(isSidebarOpen, (newValue) => {
  const body = document.body;
  if (newValue) {
    body.classList.add("!overflow-hidden");
  } else {
    body.classList.remove("!overflow-hidden");
  }
});
</script>
