<template>
  <!-- Footer container -->
  <div class="bg-lightBlack">
    <!-- Footer content -->
    <SubmissionModal v-show="showModal" @close="close" />
    <div class="xl:max-w-[1344px] px-3 container mx-auto">
      <div
        class="flex justify-between py-10 sm:py-14 md:py-20 md:pt-[104px] md:pb-[84px] flex-wrap"
      >
        <!-- Logo and social links -->
        <div
          class="w-full sm:w-1/2 lg:w-[22%] xl:w-[17%] text-center sm:text-start"
        >
          <a
            href="/"
            class="max-w-[129px] inline-block mb-5 sm:mb-10 lg:mb-[50px]"
          >
            <img
              class="w-full"
              src="/assets/images/white-logo.png"
              alt="logo"
            />
          </a>
          <p
            class="font-semibold text-white text-lg sm:text-[22px] leading-[172%] mb-5 ff_recoleta"
          >
            {{ footerData.followTitle }}
          </p>
          <div
            class="flex items-center text-red-50 gap-[13px] justify-center sm:justify-start"
          >
            <!-- Social media icons -->
            <a
              class="size-[35px] sm:size-[44px] bg-green hover:bg-yellow rounded-full flex items-center justify-center duration-300 social_icons"
              v-for="(obj, index) in footerData.socialLinks"
              :key="index"
              :href="obj.url"
            >
              <component :is="obj.icon" />
            </a>
          </div>
        </div>
        <!-- Footer links -->
        <div class="w-full sm:w-1/2 lg:w-[35%] xl:w-1/3 mt-10 sm:mt-0">
          <div class="flex justify-between text-center sm:text-start">
            <div v-for="(group, index) in footerData.links" :key="index">
              <p
                class="font-bold text-lg sm:text-xl xl:text-2xl text-white lg:pb-3 ff_recoleta"
              >
                {{ group.title }}
              </p>
              <div class="flex flex-col">
                <!-- Individual links -->
                <a
                  class="inline-block text-white text-sm sm:text-base md:text-lg mt-3 sm:mt-[17px] hover:opacity-70 duration-300"
                  v-for="(link, linkIndex) in group.links"
                  :key="linkIndex"
                  :href="link.url"
                  >{{ link.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Newsletter subscription -->
        <div class="w-full lg:w-1/3 mt-10 lg:mt-0 text-center sm:text-start">
          <p
            class="font-bold text-white text-xl sm:text-2xl mb-[10px] ff_recoleta"
          >
            {{ footerData.form.title }}
          </p>
          <p class="font-normal text-white text-lg mb-5 sm:mb-8 lg:mb-9">
            {{ footerData.form.subTitle }}
          </p>
          <form
            ref="footer-form"
            id="footer-form"
            class="bg-white flex items-center p-[5px] max-w-[430px]"
            @submit.prevent="sendEmail"
          >
            <input
              class="w-full px-3 xl:px-[31px] py-3 sm:py-[17px] bg-transparent text-lightBlack placeholder:text-lightDray focus:outline-none placeholder:font-semibold"
              type="email"
              v-model="userEmail"
              required
              placeholder="Your Email"
            />
            <button
              class="bg-green px-5 sm:px-[30px] py-3 sm:py-4 xl:py-[18px] text-sm sm:text-lg text-white leading-[140%] border border-transparent hover:border-green duration-300 hover:bg-transparent hover:text-green"
            >
              {{ footerData.form.btnText }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <!-- Footer copyright -->
    <div class="border-t border-white w-full py-5">
      <p
        class="text-white text-center text-base sm:text-lg font-normal leading-[140%]"
      >
        Copyright {{ currentYear }} {{ footerData.copyRight }}
      </p>
    </div>
  </div>
</template>
<script setup>
import SubmissionModal from "./common/SubmissionModal.vue";
// import Facebook from "./icons/FacebookIcon.vue";
// import Twitter from "./icons/TwitterIcon.vue";
// import Instagram from "./icons/InstagramIcon.vue";
// import LinkedIn from "./icons/LinkedInIcon.vue";


// Footer data
const footerData = {
  followTitle: "",//"Follow Us",
  socialLinks: [
    // { url: "https://www.facebook.com/", icon: Facebook },
    // { url: "https://twitter.com/", icon: Twitter },
    // { url: "https://www.instagram.com/", icon: Instagram },
    // { url: "https://www.instagram.com/", icon: LinkedIn },
  ],
  links: [
    // {
    //   title: "Links",
    //   links: [
    //     { title: "Home", url: "/" },
    //     { title: "About us", url: "/" },
    //     { title: "Bookings", url: "/" },
    //     { title: "Blog", url: "/" },
    //   ],
    // },
    // {
    //   title: "Legal",
    //   links: [
    //     { title: "Terms of use", url: "/" },
    //     { title: "Privacy policy", url: "/" },
    //     { title: "Cookie policy", url: "/" },
    //   ],
    // },
    // {
    //   title: "Product",
    //   links: [
    //     { title: "Take tour", url: "/" },
    //     { title: "Live chat", url: "/" },
    //     { title: "Reviews", url: "/" },
    //   ],
    // },
  ],
  form: {
    title: "Join the Waitlist",
    subTitle: "We'll let you know as soon as we are ready to go.",
    btnText: "Join",
  },
  copyRight: " Canal All Rights Reserved.",
};
// Current year
const currentYear = new Date().getFullYear();

// import JS dependencies
import { ref } from "vue";
import { useModal } from "./common/mixins/modal";
import Emailer from "../services/email.js";
// reactive variable for the input user email
const userEmail = ref("");
// initialize the useModal composable
const { showModal, toggleModal, closeModal } = useModal();
// send email caller function
const sendEmail = () => {
  try {
    // init emailer
    const emailer = new Emailer();
    const emailParams = { "user_email": userEmail.value };
    // attempt send
    const result = emailer.sendJoinWaitlistEmail(emailParams);
    // NOTE this result could be true or false. As things are we are indicating
    // success regardless and moving on.
    if (result) {
      // show the modal
      toggleModal();
    } else {
      // NOTE this condition is broken out in the name of readable and ease of extensibility
      // since the next iteration should include an error path here.
      toggleModal();
    }
  } catch (error) {
    // NOTE we are popping the modal even in the case of an error for now...
    // don't want to make a bad first impression!
    toggleModal();
  }
};
  // close the modal that pops after submitting user's email to the waitlist
  const close = () => {
    // clear form
    clearForm();
    // close the modal
    closeModal();
  };
  // clear the previously entered user input
  const clearForm = () => {
    userEmail.value = "";
  };
</script>
