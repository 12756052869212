<template>
  <!-- Back to top button -->
  <div
    v-if="showBackToTop"
    class="size-10 flex items-center justify-center bg-green rounded-md fixed bottom-5 right-5 animate-bounce duration-300 cursor-pointer"
    @click="scrollToTop"
  >
    <BackToTopArrow />
    <!-- Display arrow icon -->
  </div>
</template>

<script setup>
import BackToTopArrow from "../icons/BackToTopArrow.vue"; // Import BackToTopArrow component

const { ref, onMounted, onUnmounted } = require("vue"); // Import Vue composition API

const showBackToTop = ref(false); // Reactive variable to control button visibility

// Function to handle scroll event
const handleScroll = () => {
  if (window.pageYOffset > 300) {
    showBackToTop.value = true; // Show button when scrolled down beyond 300 pixels
  } else {
    showBackToTop.value = false; // Hide button when scrolled to top
  }
};

// Function to scroll to the top of the page smoothly
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Smooth scrolling behavior
  });
};

// Add event listener when component is mounted
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

// Remove event listener when component is unmounted
onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
