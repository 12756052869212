<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
  >
    <g clip-path="url(#clip0_23_348)">
      <path
        d="M35.6688 18.969V14.8023C35.6688 12.5011 33.8033 10.6356 31.5021 10.6356H6.50212C4.20093 10.6356 2.33545 12.5011 2.33545 14.8023V27.3023C2.33545 29.6035 4.20093 31.469 6.50211 31.469H14.5378"
        stroke="#127780"
        stroke-width="4.16667"
      />
      <rect
        x="14.8354"
        y="18.969"
        width="33.3333"
        height="20.8333"
        rx="4.16667"
        stroke="#127780"
        stroke-width="4.16667"
      />
      <circle
        cx="31.5021"
        cy="29.3857"
        r="4.16667"
        stroke="#127780"
        stroke-width="4.16667"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_348">
        <rect
          width="50"
          height="50"
          fill="white"
          transform="translate(0.251953 0.218994)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
