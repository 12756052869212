<template>
  <div
    id="app"
    v-if="isLoading"
    class="min-h-screen bg-white top-0 w-full left-0 z-10 fixed flex"
  >
    <lottie-vue
      class="!h-screen flex items-center justify-center !w-[180px] md:!w-[250px] xl:!w-[300px]"
      :options="defaultOptions"
      :height="140"
      :width="300"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script setup>
import * as animationData from "../../../public/assets/json/preloader.json";

import { ref } from "vue";
const isLoading = ref(true);
setTimeout(() => {
  isLoading.value = false;
  document.body.style.overflow = "auto";
}, 2500);

const defaultOptions = {
  animationData: animationData,
};
</script>
