<template>
  <div class="relative" id="product">
    <span
      class="absolute top-[35%] left-0 -translate-y-1/2 z-[-1] w-full max-w-[100px] sm:max-w-[200px] md:max-w-[300px] lg:max-w-[500px] left_yellow_shadow"
    >
      <PurpleShadowLeft />
    </span>
    <span
      class="absolute top-[80%] right-0 -translate-y-1/2 z-[-1] w-full max-w-[100px] sm:max-w-[200px] md:max-w-[300px] lg:max-w-[550px] left_yellow_shadow"
    >
      <YellowShadowRight />
    </span>
    <div class="xl:max-w-[1344px] px-3 container mx-auto">
      <div class="py-10 sm:py-14 md:py-24 lg:py-32 xl:py-[150px]">
        <h2
          class="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-[50px] text-lightBlack text-center mb-5 sm:mb-7 md:mb-10 lg:mb-[50px] ff_recoleta"
        >
          {{ tideMinutesData.title }}
        </h2>
        <img
          class="w-full max-w-[650px] lg:max-w-[750px] xl:max-w-[1080px] mx-auto"
          src="/assets/images/tide-minutes/mobile-group.webp"
          alt="elements"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import PurpleShadowLeft from "./icons/PurpleShadowLeft.vue";
import YellowShadowRight from "./icons/YellowShadowRight.vue";
const tideMinutesData = {
  title: "Turn The Tide In Minutes",
};
</script>
