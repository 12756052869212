<template>
  <svg width="926" height="875" viewBox="0 0 926 875" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5" filter="url(#filter0_f_22_12517)">
<path d="M576.308 504.272C445.321 208.881 587.436 462.743 201.298 -82.6651C373.063 -36.6407 516.259 66.166 617.55 399.749C798.414 638.605 633.533 451.471 724.292 648.809C586.803 559.428 705.992 839.028 576.308 504.272Z" fill="url(#paint0_linear_22_12517)"/>
<path d="M576.308 504.272C445.321 208.881 587.436 462.743 201.298 -82.6651C373.063 -36.6407 516.259 66.166 617.55 399.749C798.414 638.605 633.533 451.471 724.292 648.809C586.803 559.428 705.992 839.028 576.308 504.272Z" stroke="#7210FF"/>
</g>
<defs>
<filter id="filter0_f_22_12517" x="0.0893555" y="-283.506" width="925.379" height="1158.03" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_22_12517"/>
</filter>
<linearGradient id="paint0_linear_22_12517" x1="487.295" y1="718.386" x2="640.683" y2="145.936" gradientUnits="userSpaceOnUse">
<stop stop-color="#7210FF"/>
<stop offset="0.46" stop-color="#127780"/>
<stop offset="1" stop-color="#FAD000"/>
</linearGradient>
</defs>
</svg>

</template>
