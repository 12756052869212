<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 4.13416C20.6569 4.13416 22 5.4773 22 7.13416V17.1342C22 18.791 20.6569 20.1342 19 20.1342H5C3.34315 20.1342 2 18.791 2 17.1342V7.13416C2 5.4773 3.34315 4.13416 5 4.13416H19ZM20 7.46216L12.6585 13.8867C12.3129 14.1891 11.8111 14.2143 11.4394 13.9623L11.3415 13.8867L4 7.46316V17.1342C4 17.6864 4.44772 18.1342 5 18.1342H19C19.5523 18.1342 20 17.6864 20 17.1342V7.46216ZM18.48 6.13416H5.518L12 11.8054L18.48 6.13416Z"
      fill="#424242"
    />
  </svg>
</template>
