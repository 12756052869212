<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
  >
    <path
      d="M36.4426 14.9668C36.4426 14.9668 35.0749 8.34033 25.504 8.34033C15.933 8.34033 13.3929 12.3572 13.1985 15.1241C13.004 17.8911 13.004 24.1411 25.504 24.1411C38.004 24.1411 38.004 31.4327 38.004 32.4744C38.004 33.5161 37.8095 41.4729 25.504 41.4729C13.1985 41.4729 13.1985 34.8464 13.1985 34.8464"
      stroke="#127780"
      stroke-width="4.16667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.5039 2.26611V48.0994"
      stroke="#127780"
      stroke-width="4.16667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
