<template>
  <!-- Mission section -->
  <div
    class="bg-lightBlue py-10 sm:py-14 md:py-20 lg:py-32 xl:py-[164px]"
    id="mission"
  >
    <div class="xl:max-w-[1344px] px-3 container mx-auto">
      <div
        class="flex items-center justify-between gap-5 sm:gap-10 flex-col-reverse lg:flex-row"
      >
        <!-- Mission content -->
        <div class="w-full max-w-[600px]">
          <!-- Mission title -->
          <h2
            class="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-[50px] text-lightBlack max-w-[600px] mb-3 sm:mb-[21px] !leading-[120%] text-center lg:text-start ff_recoleta"
          >
            {{ fightingData.title }}
          </h2>
          <!-- Mission description -->
          <p
            class="font-normal text-base sm:text-lg text-lightBlack leading-[140%] pb-3 sm:pb-[20px] text-center lg:text-start"
          >
            {{ fightingData.description }}
          </p>
          <!-- Mission cards -->
          <div
            v-for="(data, index) in fightingData.cards"
            :key="index"
            class="bg-white border border-[#EEEEEE] py-3 xs:py-4 xl:py-[25px] px-3 sm:px-4 xl:px-[30px] mt-4 sm:mt-6 md:mt-[30px] flex items-center justify-between duration-300 hover:shadow-xl gap-2"
          >
            <div class="flex items-center gap-2 sm:gap-5">
              <!-- Icon -->
              <div
                class="w-full max-w-[30px] sm:max-w-[40px] md:max-w-[50px] category_icon"
              >
                <component :is="data.icon" />
              </div>
              <!-- Title -->
              <p
                class="text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-black"
              >
                {{ data.title }}
              </p>
            </div>
            <!-- Progress -->
            <p
              class="text-darkPink text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl font-bold"
            >
              {{ data.progress }}
            </p>
          </div>
        </div>
        <!-- Illustration -->
        <img
          class="w-full max-w-[350px] lg:max-w-[450px] xl:max-w-[615px]"
          src="/assets/images/fighting/illustration.webp"
          alt="illustration"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
// Importing icons
import Cash from "./icons/CashIcon.vue";
import Dollar from "./icons/DollarIcon.vue";
import Card from "./icons/CardIcon.vue";

// Custom fighting data
const fightingData = {
  title: "Ganamos Solo Cuando Tu Ganas.",
  description:
    "We win only when you are spending or saving more. Spend with the Canal Card and eliminate remittance, check cashing, and spend card fees forever.",
  cards: [
    { title: "Check Cashing", progress: "4.5-9.9% Fee", icon: Cash },
    { title: "Remittance", progress: "4-11.5% Fee", icon: Dollar },
    { title: "Prepaid Card Subscription / Reload Fee", progress: "$3.95-$6.95", icon: Card },
  ],
};
</script>

<style>
.category_icon svg {
  width: 100%;
  height: 100%;
}
</style>
