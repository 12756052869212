<template>
<div class="modal-backdrop">
    <div class="modal bg-green font-bold text-white">
        <header class="modal-header ff_recoleta">
            <slot name="header">
                Submission Received
            </slot>
            <button>
                <span 
                    class="btn-close" 
                    @click="close"
                >
                    X
                </span>
            </button>
        </header>    
        <section class="modal-body ff_recoleta">
            <slot name="body">
                Thanks for joining the waitlist! Hablamos pronto.
            </slot>
        </section>
        <section class="modal-footer">
            <slot name="footer">
            </slot>
            <button
                type="button"
                class="bg-white font-normal md:text-lg text-green py-3 sm:py-3 w-16"
                @click="close"
            >
                OK
            </button>
        </section>
    </div>
</div>
</template>
<script>

export default {
    setup(_, context) {
        // Data for the Modal
        const modalDefaultText = {
            "headerBtnText": "X",
            "footerBtnText": "OK",
        }
        // close method to toggle the modal from a parent component
        const close = () => {
            // emit the close event
            context.emit("close");
        }
        // return data and methods to the template
        return {
            modalDefaultText,
            close,
        }
    }
};
</script>

<style>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    justify-content: space-between;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-body {
    position: relative;
    padding: 20px 10px;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    background: transparent;
}

.btn-close-footer {
    color: #0c7a79;
    background: white;
    border: 1px solid #0c7a79;
    border-radius: 2px;
}

</style>