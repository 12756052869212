<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="583"
    height="1193"
    viewBox="0 0 583 1193"
    fill="none"
  >
    <g opacity="0.5" filter="url(#filter0_f_22_12518)">
      <path
        d="M820.344 825.887C595.96 518.236 820.344 773.278 251.974 250.927C448.554 250.927 630.874 327.088 834.477 689.286C1095.95 914.32 866.342 745.356 1019.72 949.856C847.332 887.755 1054.6 1180.48 820.344 825.887Z"
        fill="url(#paint0_linear_22_12518)"
      />
      <path
        d="M820.344 825.887C595.96 518.236 820.344 773.278 251.974 250.927C448.554 250.927 630.874 327.088 834.477 689.286C1095.95 914.32 866.342 745.356 1019.72 949.856C847.332 887.755 1054.6 1180.48 820.344 825.887Z"
        stroke="#7210FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_22_12518"
        x="0.690918"
        y="0.426758"
        width="1270.43"
        height="1254.92"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="125"
          result="effect1_foregroundBlur_22_12518"
        />
      </filter>
      <linearGradient
        id="paint0_linear_22_12518"
        x1="786.558"
        y1="1107.15"
        x2="786.558"
        y2="381.966"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7210FF" />
        <stop offset="0.46" stop-color="#127780" />
        <stop offset="1" stop-color="#FAD000" />
      </linearGradient>
    </defs>
  </svg>
</template>
