<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.2915 14.7297C2.2915 11.3929 4.99645 8.68799 8.33317 8.68799H41.6665C45.0032 8.68799 47.7082 11.3929 47.7082 14.7297V18.8963V35.563C47.7082 38.8997 45.0032 41.6047 41.6665 41.6047H8.33318C4.99646 41.6047 2.2915 38.8997 2.2915 35.563V18.8963V14.7297ZM8.33317 12.438C7.06752 12.438 6.0415 13.464 6.0415 14.7297V17.0213H43.9582V14.7297C43.9582 13.464 42.9322 12.438 41.6665 12.438H8.33317ZM43.9582 20.7713H6.0415V35.563C6.0415 36.8286 7.06752 37.8547 8.33318 37.8547H41.6665C42.9322 37.8547 43.9582 36.8286 43.9582 35.563V20.7713ZM27.2915 31.3963C27.2915 30.3608 28.131 29.5213 29.1665 29.5213H37.4998C38.5354 29.5213 39.3748 30.3608 39.3748 31.3963C39.3748 32.4319 38.5354 33.2713 37.4998 33.2713H29.1665C28.131 33.2713 27.2915 32.4319 27.2915 31.3963Z"
      fill="#127780"
    />
  </svg>
</template>
