<template>
  <!-- Main section container -->
  <section class="relative min-h-screen overflow-hidden z-[1]">
    <!-- Waitlist submission modal (conditionally rendered on submission) -->
    <SubmissionModal v-show="showModal" @close="close" />
    <!-- Hero shadows -->
    <span class="absolute top-0 left-0 z-[-1] shadow-animation">
      <HeroLeftTopShadow />
    </span>
    <span class="absolute top-0 left-0 z-[-1] shadow-animation">
      <HeroMidShadow />
    </span>
    <!-- Navigation bar -->
    <NavBar />
    <!-- Main content container -->
    <div
      class="xl:max-w-[1344px] px-3 container mx-auto flex flex-col-reverse lg:flex-row items-center justify-between min-h-[calc(100vh-116px)] pt-4 sm:pt-0"
    >
      <!-- Left column -->
      <div class="lg:max-w-[660px] text-center lg:text-start">
        <!-- Title -->
        <h1
          class="text-lightBlack font-bold text-[32px] sm:text-4xl md:text-[40px] lg:text-[44px] xl:text-[64px] !leading-[120%] ff_recoleta"
        >
          {{ heroArray.title }}
        </h1>
        <!-- Description -->
        <p
          class="text-greyscale font-bold mt-4 lg:mt-[30px] mb-4 lg:mb-8 xl:mb-[50px] text-base md:text-lg lg:text-xl xl:text-2xl leading-[141%]"
        >
          {{ heroArray.description }}
        </p>
        <!-- Waitlist text -->
        <p
          class="text-green font-bold text-base md:text-lg lg:text-xl xl:text-2xl leading-[141%] mb-4"
        >
          {{ heroArray.waitListText }}
        </p>
        <!-- Email form -->
        <form
          ref="mainform"
          id="mainform"
          class="flex justify-center lg:justify-start items-center gap-2 xs:gap-3 sm:gap-5"
          @submit.prevent="sendEmail"
        >
          <!-- Email input -->
          <div
            class="relative w-[65%] sm:w-full max-w-[300px] xl:max-w-[391px]"
          >
            <span
              class="absolute top-1/2 translate-y-[-50%] left-3 sm:left-5 hidden xs:block"
            >
              <Mail class="max-w-[20px] h-auto sm:max-w-none" />
            </span>
            <input
              class="py-3 sm:py-4 px-3 xs:ps-9 sm:ps-[54px] border border-[#E0E0E0] bg-[#EEEEEE] w-full placeholder:text-darkDray text-lightBlack text-sm sm:text-base md:text-lg font-normal focus:outline-none focus:border-lightBlack"
              type="email"
              name="email"
              v-model="userEmail"
              placeholder="Enter Your Email Address"
              required
            />
          </div>
          <!-- Submit button -->
          <button
            class="bg-green font-normal text-sm sm:text-base md:text-lg text-white py-3 sm:py-4 px-4 sm:px-6 duration-300 hover:bg-white hover:text-green border border-transparent hover:border-green"
          >
            {{ heroArray.btnText }}
          </button>
        </form>
      </div>
      <!-- Right column -->
      <div class="max-w-[350px] lg:max-w-[480px] xl:max-w-[546px]">
        <!-- Hero image -->
        <img
          class="w-full"
          src="/assets/images/hero/hero-img.webp"
          alt="hero-img"
        />
      </div>
    </div>
  </section>
</template>
<script setup>
// Importing components
import NavBar from "./NavBar.vue";
import HeroLeftTopShadow from "./icons/HeroLeftTopShadow.vue";
import HeroMidShadow from "./icons/HeroMidShadow.vue";
import Mail from "./icons/MailIcon.vue";
import SubmissionModal from "./common/SubmissionModal.vue";
import Emailer from "../services/email.js";
import { useModal } from "./common/mixins/modal.js";
// Data for the hero section
const heroArray = {
  title: "The Route to Financial Efficiency is Here",
  description:
    "Turn fees of check cashing and remittances into increased saving and spending.",
  waitListText: "Join the Waitlist",
  btnText: "Join Now",
};

// import JS dependencies
import { ref } from "vue";
// reactive variable for the input user email
const userEmail = ref("");
// initialize the useModal composable
const { showModal, toggleModal, closeModal } = useModal();
// send email caller function
const sendEmail = () => {
  try {
    // init emailer
    const emailer = new Emailer();
    const emailParams = { "user_email": userEmail.value };
    // attempt send
    const result = emailer.sendJoinWaitlistEmail(emailParams);
    // NOTE this result could be true or false. As things are we are indicating
    // success regardless and moving on.
    if (result) {
      // show the modal
      toggleModal();
    } else {
      // NOTE this condition is broken out in the name of readable and ease of extensibility
      // since the next iteration should include an error path here.
      toggleModal();
    }
  } catch (error) {
    // NOTE we are popping the modal even in the case of an error for now...
    // don't want to make a bad first impression!
    toggleModal();
  }
}
// /close the modal that pops after submitting user's email to the waitlist
const close = () => {
  // clear form
  clearForm();
  // close the modal
  closeModal();
};
// clear the previously entered user input
const clearForm = () => {
  userEmail.value = "";
};

</script>
